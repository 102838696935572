<template>
  <div class="GalleryCategories" v-if="hasCategories">
    <Carrousel :settings="carrousel" v-if="hasCategories">
      <div
        class="GalleryFileWrap"
        v-for="category in items"
        :key="category._id"
        @click="setSelected(category), setQuery({ category: category._id })"
        active="button"
      >
        <GalleryFile :item="category" :class="{ selected: category._id === $route.query.category }" />
        <div class="CategoryLabel">{{ category.name }}</div>
      </div>
    </Carrousel>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
export default {
  mixins: [FeedList],
  components: {
    GalleryFile: () => import("./GalleryFile.vue"),
  },
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/gallery/categories`,
      storeId: "gallery_categories",
      items: [],
      statistics: { total: 0, page: 1 },
      limit: 20,
    };
  },
  methods: {
    loaded: function() {
      const current = this.items.find((item) => item._id === this.$route.query.category);
      if (current) {
        this.setSelected(current);
      }
    },
    setSelected: function(category) {
      this.state({ gallery_category: category });
    },
  },
  computed: {
    hasCategories: function() {
      return this.items.length > 0;
    },
    carrousel: function() {
      return {
        items: 3,
        slidesToScroll: 3,
        variableWidth: true,
      };
    },
    sizeButton: function() {
      if (this.$isDesktop) {
        return Go.math(this.$mpadding, "*", 5);
      }

      if (this.$isTablet) {
        return Go.math(this.$mpadding, "*", 4);
      }

      return Go.math(this.$mpadding, "*", 4);
    },
  },
};
</script>
